import styled from "styled-components";

export const BestillPrøvekjøringLayout = styled.div`
  margin-top: var(--sp80);
  display: flex; 
  flex-direction: column;
  margin-bottom: var(--sp64);
}
`;

export const PrøvekjøringOpel = styled.div`
  display: flex; 
  flex-direction: row;
}
`;
