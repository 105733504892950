import * as Yup from "yup";
import React from "react";
import { PrivacyLayout } from "./styles";
import { useContext, useState } from "react";
import { AllDataContext } from "../../lib/dataContext";
import Table from "../../components/table-privacy";
const BlockContent = require("@sanity/block-content-to-react");

const PrivacyPage = () => {
  const { brandCode, privacy } = useContext(AllDataContext);
  const BlockRenderer = (props: any) => {
    const { style = "normal" } = props.node;

    if (/^h\d/.test(style)) {
      const level = style.replace(/[^\d]/g, "");
      return React.createElement(
        style,
        { className: `h${level}-clause` },
        props.children
      );
    }

    if (style === "blockquote") {
      return <blockquote>- {props.children}</blockquote>;
    }

    // Fall back to default handling
    return BlockContent.defaultSerializers.types.block(props);
  };
  return (
    <PrivacyLayout>
      <h1>INFORMASJONSKAPSELPOLICY</h1>
      <BlockContent
        blocks={privacy.clause}
        serializers={{ types: { block: BlockRenderer } }}
      />
      {privacy.privacyChart ? <Table rows={privacy.privacyChart.rows} /> : ""}
    </PrivacyLayout>
  );
};

export default PrivacyPage;
