import { useContext } from "react";
import { AllDataContext } from "../../../lib/dataContext";
import JeepLogo from "../../../logos/jeep";
import {
  FooterLink,
  Inner,
  LinkContainer,
  JeepFooterContainer,
} from "./styles";

const JeepFooter = () => {
  const data = useContext(AllDataContext);

  const links = data.footerItems.footerLinks;

  return (
    <JeepFooterContainer>
      <Inner>
        <JeepLogo />
        <LinkContainer>
          {links?.map((link) => (
            <FooterLink
              className="p2"
              href={link.href}
              rel="noreferrer noopener"
              target="_blank"
              key={link._key}
            >
              {link.title}
            </FooterLink>
          ))}
        </LinkContainer>
      </Inner>
    </JeepFooterContainer>
  );
};

export default JeepFooter;
