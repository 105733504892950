import styled from "styled-components";
import AlfaLogo from "../../logos/alfa";

const HeaderLink = styled.a`
  font-size: 12px;
`;

const AlfaHeader = () => {
  return (
    <>
      <a href="https://www.alfaromeo.no/">
        <AlfaLogo />
      </a>
      <HeaderLink
        href="https://www.alfaromeo.no/"
        rel="noopener noreferrer"
        target="_blank"
      >
        Til AlfaRomeo.NO
      </HeaderLink>
    </>
  );
};

export default AlfaHeader;
