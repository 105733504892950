import * as Yup from "yup";

export const buildMaxCharMessage = (max: number) => `Maks antall tegn er ${max}`

export const yupPhoneValidation = Yup.string()
  .min(8, "Telefonnummeret må være minst 8 tegn.")
  .max(50, buildMaxCharMessage(50))
  .test(
    "phone-format",
    "Ugyldig telefonnummer. Gyldige formater er: 99999999, +47 99999999, 0047 99999999",
    (value: string | undefined) => {
      if (!value) return false;
      const cleanedValue = value.replace(/\s+/g, '');

      if (cleanedValue.length === 8 && !cleanedValue.startsWith('+47') && (cleanedValue.startsWith('4') || cleanedValue.startsWith('9'))) {
        return true;
      }
      
      if (cleanedValue.startsWith('0047') && cleanedValue.length === 12 && (cleanedValue.slice(4).startsWith('4') || cleanedValue.slice(4).startsWith('9'))) {
        return true;
      }
      
      if (cleanedValue.startsWith('+47') && cleanedValue.length === 11 && (cleanedValue.slice(3).startsWith('4') || cleanedValue.slice(3).startsWith('9'))) {
        return true;
      }
      
      return false;
    }
  );
