import styled from "styled-components";
import CitroenLogo from "../../logos/citroen";

const HeaderLink = styled.a`
  font-family: "CitroenBold";
  font-size: 12px;
`;

const CitroenHeader = () => {
  return (
    <>
      <a href="https://www.citroen.no/">
        <CitroenLogo />
      </a>
      <HeaderLink
        href="https://www.citroen.no/"
        rel="noopener noreferrer"
        target="_blank"
      >
        TIL CITROEN.NO
      </HeaderLink>
    </>
  );
};

export default CitroenHeader;
