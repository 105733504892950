import styled from "styled-components";
import { device } from "../../../styles/breakpoints";

export const PeugeotFooterContainer = styled.footer`
  background: var(--black);
  padding: 1rem 2rem;
  margin-top: var(--sp80);
`;

export const Inner = styled.div`
  max-width: 1312px;
  margin: 0 auto;
  display: grid;
  place-items: center;
  grid-template-rows: max-content 1fr;
  @media ${device.mobileMax} {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 1rem;
  }
`;

export const LinkContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  a:not(:first-child) {
    margin-left: var(--sp16);
  }

  @media ${device.mobileMax} {
    flex-direction: column;
    text-align: center;
    margin-bottom: var(--sp24);
    margin-top: 0;
    a {
      margin-left: 0 !important;
      margin-top: var(--sp16);
    }
  }
`;

export const FooterLink = styled.a`
  text-decoration: none;
  color: var(--grey-medium);
  cursor: pointer;
`;
