import styled from "styled-components";
import { device } from "../../styles/breakpoints";
import { VerticalDivider } from "../car-category/styles";

export const ReceiptLayout = styled.div`
  h1 {
    margin-top: var(--sp80);
    text-transform: uppercase;
    @media ${device.tabletMax} {
      margin-top: var(--sp32);
    }
  }

  @media ${device.tabletMax} {
    padding: 0 var(--sp24);
  }
  ${VerticalDivider} {
    margin: var(--sp64) 0;
  }
`;

export const ReceiptTextContainer = styled.div`
  display: flex;
  max-width: 640px;
  flex-direction: column;
  margin-top: var(--sp24);
  p {
    margin-top: var(--sp16);
  }
`;

export const ExploreMoreUrlContainer = styled.div`
  .h3 {
    margin-bottom: var(--sp32);
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -16px;
  a {
    margin: var(--sp16);
  }
`;

export const ExploreMoreLink = styled.a`
  display: flex;
  align-items: center;
  width: max-content;
  padding: var(--sp16) var(--sp24);
  text-decoration: none;
  svg {
    margin-left: 22px;
    height: 20px;
  }
`;
export const ExploreMoreLinkOpel = styled.a`
  display: flex;
  align-items: center;
  width: max-content;
  padding: var(--sp16) var(--sp24);
  text-decoration: none;
  img {
    margin-right: 16px;
    width: 12px;
  }
`;
export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ImageContainer = styled.div`
  max-height: 361px;
  margin-top: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  img {
    margin: var(--sp24) auto;
    max-height: 275px;
    height: 100%;
  }
`;