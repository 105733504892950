import {
  CustomLink,
  ImageInfoContainer,
} from "../../forms/test-drive/personalia/styles";
import { css } from "styled-components";
import {
  CardContainer,
  Divider,
  TestDriveTextContainer,
} from "../../components/car-card/styles";
import {
  CarCategoryContainer,
  CategoryHeader,
  VerticalDivider,
} from "../../components/car-category/styles";
import {
  ChosenDealerContainer,
  Dropdown,
  PickOtherDealerButton,
} from "../../components/dealer-lookup/styles";
import { LoadingSpinnerStyle } from "../../components/loading-spinner/styles";
import { ExploreMoreLink } from "../../components/receipt/styles";
import { SubmitButtonContactUs } from "../../forms/contact/styles";
import { SubmitButtonNewsLetter } from "../../forms/newsletter/styles";
import {
  Abbr,
  FieldContainer,
  FieldErrorText,
  SubmitButtonTestDrive,
  TestDriveBackButton,
} from "../../forms/test-drive/personalia/styles";
import { HeaderContainer } from "../containers";
import { device } from "../breakpoints";

export const AlfaStyle = css`
  --white: #ffffff;
  --black: #000000;
  --red: #bb4429;
  --pinkish: #dc5f73;
  --agent: #eb6428;
  --green: #a5b43c;
  --azur: #78c3d7;
  --deep-blue: #148cb4;
  --dark-turquoise: #0000aa;
  --fresh-green: #50beaa;
  --purplish-grey: #b9b9cd;
  --brownish-grey: #a09696;
  --error-caption: #dc3545;
  --maroon: #ad0a32;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6  {
    font-family: "ApexNew-Bold", "Helvetica,Arial", sans-serif !important;
    text-transform: uppercase;
  }

  h1,
  .h1 {
    color: var(--maroon);
  }

  ${HeaderContainer} {
    background: var(--white);
    border-bottom: 1px solid var(--brownish-grey);
    a {
      color: var(--maroon);
      text-decoration: none;
    }
    -webkit-box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.52);
    box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.52);
  }

  ${PickOtherDealerButton} {
    background: var(--white);
    text-decoration-color: var(--black);
    text-decoration-thickness: 3px;
    text-underline-offset: 5px;
  }

  ${CardContainer} {
    &.picked {
      border: 1px solid var(--maroon);
    }
  }

  ${TestDriveTextContainer} {
    background: var(--maroon);
    color: var(--white);
    text-transform: uppercase;
  }

  ${Divider} {
    color: var(--maroon);
  }

  ${VerticalDivider} {
    color: var(--maroon);
  }

  ${SubmitButtonTestDrive} {
    background: var(--maroon);
    color: var(--white);
    font-family: "ApexNew-Book, " Helvetica, Arial ", sans-serif";
    text-transform: uppercase;
  }

  ${SubmitButtonContactUs} {
    background: var(--maroon);
    color: var(--white);
    font-family: "ApexNew-Book, " Helvetica, Arial ", sans-serif";
    text-transform: uppercase;
  }
  ${SubmitButtonNewsLetter} {
    background: var(--maroon);
    color: var(--white);
    font-family: "ApexNew-Book, " Helvetica, Arial ", sans-serif";
    text-transform: uppercase;
  }

  ${FieldContainer} {
    input,
    textarea,
    input[type="number"],
    select {
      font-size: 16px;
      border-radius: 0;
      border: 1px solid var(--black);
    }
  }

  ${FieldErrorText} {
    color: var(--error-caption);
  }

  ${TestDriveBackButton} {
    background: var(--white);
    text-decoration-color: var(--maroon);
    text-underline-offset: 5px;
    text-decoration-thickness: 3px;
  }

  ${Dropdown} {
    background-image: url("data:image/svg+xml;utf8,<svg fill='%23eb6428' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    border: 1px solid var(--black);
  }

  ${Abbr} {
    color: var(--error-caption);
  }

  ${LoadingSpinnerStyle} {
    .loader,
    .loader:after,
    .loader:before {
      background: var(--maroon);
    }
    .loader {
      color: var(--maroon);
    }
  }

  ${ExploreMoreLink} {
    border: 1px solid var(--black);
    color: var(--black);
    font-family: "ApexNew-Book, " Helvetica, Arial ", sans-serif";
    background: var(--maroon);
    path {
      fill: var(--white);
    }
  }

  ${CarCategoryContainer} {
    h2 {
      color: var(--maroon);
    }
  }

  ${ChosenDealerContainer} {
    svg {
      path {
        fill: var(--maroon);
      }
    }
  }

  ${CustomLink} {
    text-underline-offset: 5px;
    text-decoration-color: var(--maroon);
    text-decoration-thickness: 3px;
  }

  ${CategoryHeader} {
    color: var(--maroon);
  }

  ${ImageInfoContainer} {
    border: 1px solid var(--brownish-grey);
    @media ${device.tabletMax} {
      border: none;
    }
  }
`;
