import { Form, Formik } from "formik";
import * as Yup from "yup";
import { NewsLetterLayout } from "./styles";
import { useContext, useState } from "react";
import {
  INewsLetterPageFormValues,
} from "../../../../common/typedefs";
import { FieldErrorText } from "../../forms/test-drive/personalia/styles";
import Receipt from "../../components/receipt";
import { sendToGTM } from "../../lib/sendToGTM";
import { AllDataContext } from "../../lib/dataContext";
import NewsLetterForm from "../../forms/newsletter";
import { buildMaxCharMessage } from "../../lib/phoneValidation";

export const NewsLetterSignUpValidation = Yup.object().shape({
  fname: Yup.string().max(50, buildMaxCharMessage(50)).required("Påkrevd"),
  lname: Yup.string().max(100, buildMaxCharMessage(100)).required("Påkrevd"),
  email: Yup.string().max(100, buildMaxCharMessage(100)).email("Ugyldig epost").required("Påkrevd"),
});

const NewsletterPage = () => {
  const [step, setStep] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);

  const { contactUsTexts, brandCode, newsLetterPage } =
    useContext(AllDataContext);

  const initialValues: INewsLetterPageFormValues = {
    fname: "",
    lname: "",
    email: "",
  };

  return (
    <NewsLetterLayout>
      <Formik
        validationSchema={NewsLetterSignUpValidation}
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          setError(false);
          const data = JSON.stringify({
            firstName: values.fname,
            lastName: values.lname,
            email: values.email,
          });
          try {
            await fetch("/api/boscrm/newsletter", {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: data,
            });
            actions.setSubmitting(false);
            setStep(1);
          } catch (error) {
            sendToGTM({
              event: "error",
              field_name: "Send to BOSCRM | NewsLetter ",
              error_code: "400",
              error_text: error,
            });
            setError(true);
          }
        }}
      >
        {({ values, isSubmitting, errors, touched, isValid }) => (
          <Form>
            {error && (
              <FieldErrorText>
                Noe gikk galt, vennligst prøv på nytt
              </FieldErrorText>
            )}
            {step === 0 ? (
              <NewsLetterForm
                isSubmitting={isSubmitting}
                errors={errors}
                touched={touched}
                isValid={isValid}
              />
            ) : (
              <Receipt
                urls={contactUsTexts.exploreURLs}
                email={values.email}
                brandCode={brandCode}
                formType="newsletter"
                carInfo={null}
                image={contactUsTexts.image}
                receiptText={newsLetterPage.receiptText}
              />
            )}
          </Form>
        )}
      </Formik>
    </NewsLetterLayout>
  );
};

export default NewsletterPage;
