import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { GlobalStyle } from "./styles/globalStyle";
import ContactUsPage from "./pages/contact-us";
import TestDrivePage from "./pages/test-drive";
import NewsLetterPage from "./pages/newsletter";
import OfferPage from "./pages/offer";
import PrivacyPage from "./pages/privacy";
import { useContext, useRef } from "react";
import DataContextProvider, { AllDataContext } from "./lib/dataContext";
import Header from "./components/headers";
import Footer from "./components/footers";
import { useOnLoadImages } from "./components/pageScrollFunctionality/ImageLoaded";

const App = () => {
  return (
    <DataContextProvider>
      <GlobalStyle />
      <Main />
    </DataContextProvider>
  );
};

function ScrollPage() {
  const { hash } = window.location;
  const elementToScroll = document.getElementById(hash?.replace("#", "").toLowerCase());
  elementToScroll?.scrollIntoView({ behavior: "smooth" });
}

const Main = () => {
  const data = useContext(AllDataContext);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  return (
    <div className={`App ${data.brandCode}`} ref={wrapperRef}>
      <Router>
        <Header />
        <main>
          {imagesLoaded ? ScrollPage() : ""}
          <Switch>
            <Route path="/book-provekjoring">
              <TestDrivePage />
            </Route>
            <Route path="/tilbud">
              <OfferPage />
            </Route>
            <Route path="/informasjonskapselpolicy">
              <PrivacyPage />
            </Route>
            <Route path="/nyhetsbrev">
              <NewsLetterPage />
            </Route>
            <Route path="/">
              <ContactUsPage />
            </Route>
          </Switch>
        </main>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
