import {
  CardContainer,
  Divider,
  InfoContainer,
  TestDriveTextContainer,
} from "./../../components/car-card/styles";
import { css } from "styled-components";

import {
  VerticalDivider,
  CategoryHeader,
} from "../../components/car-category/styles";
import { HeaderContainer } from "../containers";
import {
  Abbr,
  CustomLink,
  FieldContainer,
  FieldErrorText,
  SubmitButtonTestDrive,
  TestDriveBackButton,
} from "../../forms/test-drive/personalia/styles";
import { SubmitButtonContactUs } from "../../forms/contact/styles";
import {
  ChosenDealerContainer,
  Dropdown,
  PickOtherDealerButton,
} from "../../components/dealer-lookup/styles";
import { LoadingSpinnerStyle } from "../../components/loading-spinner/styles";
import { ExploreMoreLink } from "../../components/receipt/styles";
import { SubmitButtonNewsLetter } from "../../forms/newsletter/styles";

export const DSStyle = css`
  --black: #000000;
  --white: #ffffff;
  --champagne: #c6b8ae;
  --dark-champagne: #b4a298;
  --pale-champagne: #e9e7e1;
  --light-champagne: #d2c7be;
  --carmine: #b51e41;

  h3,
  h4,
  h5,
  h6,
  .h3,
  .h4,
  .h5,
  .h6  {
    color: var(--white);
    font-family: "DSBold";
    text-transform: uppercase;
  }

  h1,
  .h1 {
    color: var(--champagne);
    font-family: "DSTitleMediumRegular";
    text-transform: uppercase;
  }

  h2,
  .h2 {
    color: var(--white);
    font-family: "DSTitleMediumRegular";
    text-transform: uppercase;
  }

  p,
  .p,
  .p2 {
    color: var(--white);
  }

  .title-h1,
  .title-h2 {
  }

  ${HeaderContainer} {
    border-bottom: 1px solid var(--pale-champagne);
    a {
      color: var(--carmine);
      text-decoration: none;
    }
  }

  ${PickOtherDealerButton} {
    background: var(--black);
    text-decoration-color: var(--carmine);
    text-decoration-thickness: 3px;
    text-underline-offset: 5px;
  }

  ${TestDriveTextContainer} {
    background: var(--carmine);
    color: var(--white);
    text-transform: uppercase;
  }

  ${Divider} {
    color: var(--champagne);
  }

  ${VerticalDivider} {
    color: var(--pale-champagne);
  }

  ${SubmitButtonTestDrive} {
    font-family: "DSBold";
    background: var(--carmine);
    color: var(--white);
    text-transform: uppercase;
  }

  ${SubmitButtonContactUs} {
    font-family: "DSBold";
    background: var(--carmine);
    color: var(--white);
    text-transform: uppercase;
  }
  ${SubmitButtonNewsLetter} {
    font-family: "DSBold";
    background: var(--carmine);
    color: var(--white);
    text-transform: uppercase;
  }

  ${FieldContainer} {
    input,
    textarea,
    input[type="number"],
    select {
      font-size: 16px;
      border: none;
    }
  }

  ${FieldErrorText} {
    color: red;
  }

  ${TestDriveBackButton} {
    text-decoration-color: var(--carmine);
    text-underline-offset: 5px;
    text-decoration-thickness: 3px;
    background: var(--black);
  }

  ${Dropdown} {
    background-image: url("data:image/svg+xml;utf8,<svg fill='%23b51e41' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    border: none;
  }

  ${Abbr} {
    color: red;
  }

  ${InfoContainer} {
    .h6,
    p {
      color: var(--black);
    }
  }

  ${LoadingSpinnerStyle} {
    .loader,
    .loader:after,
    .loader:before {
      background: var(--carmine);
    }
    .loader {
      color: var(--carmine);
    }
  }

  ${ChosenDealerContainer} {
    h6 {
      color: var(--black);
    }
    p {
      color: black;
    }
    svg {
      path {
        fill: var(--carmine);
      }
    }
  }

  ${ExploreMoreLink} {
    font-family: "DSBold";
    border: 1px solid var(--white);
    background: var(--white);
    color: var(--black);
    text-transform: uppercase;
    path {
      fill: var(--carmine);
    }
  }

  ${CustomLink} {
    color: var(--white);
    text-underline-offset: 5px;
    text-decoration-color: var(--carmine);
    text-decoration-thickness: 3px;
  }

  ${CategoryHeader} {
    color: var(--light-champagne);
  }

  ${CardContainer} {
    background: #f7f7f7;
  }
`;
