import styled from "styled-components";
import { device } from "../../styles/breakpoints";

export const TilbudContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
export const TildbudLayout = styled.section`
  margin-top: var(--sp80);
  margin-bottom: var(--sp64);
  @media ${device.mobileMax} {
    padding: 0 var(--sp24);
    margin-top: var(--sp48);
  }
`;
