import styled from "styled-components";
import { device } from "./breakpoints";

export const InputContainer = styled.section`
  max-width: 416px;

  input {
    height: 50px;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  div:not(:first-child) {
    margin-top: var(--sp32);
  }
`;

export const CarSelectionContainer = styled.div`
  @media ${device.tabletMax} {
    padding: 0 var(--sp24);
  }
`;

export const HeaderContainer = styled.header``;

export const BlockContentContainer = styled.div`
  max-width: calc(100vw - var(--sp48));
  margin-bottom: var(--sp24);
`;
