import {
  CardContainer,
  Divider,
  InfoContainer,
  TestDriveTextContainer,
} from "../../components/car-card/styles";
import { css } from "styled-components";
import { VerticalDivider } from "../../components/car-category/styles";
import { HeaderContainer } from "../containers";
import {
  Abbr,
  CustomLink,
  FieldContainer,
  FieldErrorText,
  SubmitButtonTestDrive,
  TestDriveBackButton,
  ImageInfoContainer,
} from "../../forms/test-drive/personalia/styles";
import { SubmitButtonContactUs } from "../../forms/contact/styles";
import {
  Dropdown,
  PickOtherDealerButton,
} from "../../components/dealer-lookup/styles";
import { LoadingSpinnerStyle } from "../../components/loading-spinner/styles";
import { ExploreMoreLink } from "../../components/receipt/styles";
import { SubmitButtonNewsLetter } from "../../forms/newsletter/styles";

export const FiatProfessionalStyle = css`
  --black: #000000;
  --electric-blue: #00a3e0;
  --grey-light: #f7f7f7;
  --white: #ffffff;
  --kryptonite: #bfdd0b;
  --electric-blue-light: #4cc9f9;
  --grey-light-deep: #ececee;
  --grey-medium: #adaeb2;
  --grey-dark: #787b80;
  --error: #cc0000;
  --valid: #3a8000;
  --maroon: #ad0932;
  --fiat-blue: #01bfd9;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6  {
    font-family: "OpenSans";
  }

  .title-h1,
  .title-h2 {
    text-transform: uppercase;
  }

  ${HeaderContainer} {
    background: var(--maroon);
    color: var(--white);
    a {
      color: var(--white);
      text-decoration: none;
    }
    -webkit-box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.52);
    box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.52);
  }

  ${PickOtherDealerButton} {
    text-decoration-color: var(--fiat-blue);
    text-decoration-thickness: 3px;
    text-underline-offset: 5px;
  }

  ${CardContainer} {
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }
  ${ImageInfoContainer} {
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }

  ${TestDriveTextContainer} {
    background: var(--fiat-blue);
    color: var(--black);
    text-transform: uppercase;
    font-size: 14px;
  }

  ${Divider} {
    color: var(--maroon);
  }

  ${VerticalDivider} {
    color: var(--grey-light);
  }

  ${SubmitButtonTestDrive} {
    background: var(--fiat-blue);
    color: var(--black);
    font-family: "OpenSans";
    text-transform: uppercase;
    font-size: 14px;
  }

  ${SubmitButtonContactUs} {
    background: var(--fiat-blue);
    color: var(--black);
    font-family: "OpenSans";
    text-transform: uppercase;
    font-size: 14px;
  }
  ${SubmitButtonNewsLetter} {
    background: var(--fiat-blue);
    color: var(--black);
    font-family: "OpenSans";
    text-transform: uppercase;
    font-size: 14px;
  }

  ${FieldContainer} {
    input,
    textarea,
    input[type="number"],
    select {
      font-size: 16px;
      border-radius: 0;
      border: 2px solid var(--grey-medium);
    }
  }

  ${FieldErrorText} {
    color: var(--error);
  }

  ${TestDriveBackButton} {
    text-decoration-color: var(--fiat-blue);
    text-underline-offset: 5px;
    text-decoration-thickness: 3px;
    background: var(---grey-light);
  }

  ${Dropdown} {
    background-image: url("data:image/svg+xml;utf8,<svg fill='%2300a3e0' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    border: 2px solid var(--grey-medium);
  }

  ${Abbr} {
    color: var(--error);
  }

  ${InfoContainer} {
    p {
      color: var(--grey-dark);
    }
  }

  ${LoadingSpinnerStyle} {
    .loader,
    .loader:after,
    .loader:before {
      background: var(--maroon);
    }
    .loader {
      color: var(--maroon);
    }
  }

  ${ExploreMoreLink} {
    border: 1px solid var(--grey-medium);
    color: var(--black);
    font-family: "OpenSansBold";
    font-size: 14px;
    background: var(--white);
    text-transform: uppercase;
    path {
      fill: var(--fiat-blue);
    }
  }

  ${CustomLink} {
    text-underline-offset: 5px;
    text-decoration-color: var(--fiat-blue);
    text-decoration-thickness: 3px;
  }
`;
