import styled from "styled-components";
import { device } from "../../styles/breakpoints";

export const PrivacyLayout = styled.section`
  margin-top: var(--sp80);
  @media ${device.mobileMax} {
    padding: 0 var(--sp24);
    margin-top: var(--sp48);
  }
  .h2 {
    margin-top: var(--sp48);
  }
  .h2-clause {
    padding: var(--sp24) 0;
  }
`;
