import { Field } from "formik";
import { useContext } from "react";
import { Car } from "../../../../common/typedefs";
import { urlFor } from "../../lib/imageBuilder";
import { OfferPageContext } from "../../pages/offer";
import { TestDriveContext } from "../../pages/test-drive";
import {
  CardContainer,
  Divider,
  InfoContainer,
  MissingImageContainer,
  TestDriveTextContainer,
} from "./styles";

interface Props {
  data: Car;
  pageType: string;
}

const CarCard = (props: Props) => {
  const {
    name,
    modelseries,
    chassis,
    fuel,
    imageUrl,
    image,
    carlineCode,
    carlineName,
    segment,
    tabId,
    receiptLinks,
  } = props.data;

  const checkContext = () => {
    return props.pageType === "offer" ? OfferPageContext : TestDriveContext;
  };

  const handleCarClicked = () => {
    setSelectedCar(name);
    setSelectedCarCode(carlineCode);
    setCarInfo({
      chassis: chassis,
      fuel: fuel,
      imageUrl: imageUrl,
      image: image,
      carlineCode: carlineCode,
      carlineName: carlineName,
      segment: segment,
      modelseries: modelseries,
      receiptLinks: receiptLinks,
    });
    setStep(1);
  };

  const {
    setStep,
    setSelectedCarCode,
    selectedCarCode,
    setSelectedCar,
    setCarInfo,
  } = useContext(checkContext());

  return (
    <CardContainer
      className="picked"
      onClick={() => handleCarClicked()}
    >
      <Field
        tabIndex={tabId}
        type="radio"
        name="car"
        value={name}
        id={carlineCode}
      />
      <label htmlFor={carlineCode} className="p2">
        {urlFor(image).url() ? (
          <img
            src={urlFor(image)
              .width(615)
              .auto("format")
              .fit("crop")
              .url()
              ?.toString()}
            alt={image.alttext ?? "Bil"}
          />
        ) : (
          <MissingImageContainer>Bilde mangler</MissingImageContainer>
        )}
        <InfoContainer>
          <span className="h6">{name}</span>
          <Divider>/</Divider>
          <p className="p2">{chassis}</p>
          <Divider>/</Divider>
          <p className="p2">{fuel}</p>
        </InfoContainer>
      </label>
      <TestDriveTextContainer className="p2">
        {props.pageType === "testdrive" ? "Bestill prøvekjøring" : "Få tilbud"}
      </TestDriveTextContainer>
    </CardContainer>
  );
};

export default CarCard;
