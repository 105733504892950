import styled from "styled-components";

export const TableContainer = styled.table`
{
  border: 1px solid black;
  border-collapse: collapse;
  td, th {
    border: 1px solid black;
    padding: 5px;
  }
  margin-top: var(--sp24);
  width: 100%;
`;
