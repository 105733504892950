import { useEffect } from "react";
import {
  DealershipResponsibilityArea,
  ExploreUrl,
} from "../../../../common/typedefs";
import { getBrand } from "../../lib/getBrandFromCode";
import { sendToGTM } from "../../lib/sendToGTM";
import { CarInfo } from "../../pages/test-drive";
import { VerticalDivider } from "../car-category/styles";
import { GTMCar } from "../../../../common/typedefs";
import {
  ExploreMoreLink,
  ExploreMoreLinkOpel,
  ExploreMoreUrlContainer,
  Inner,
  ReceiptLayout,
  ReceiptTextContainer,
  HeadingContainer,
  ImageContainer,
} from "./styles";
import { urlFor } from "../../lib/imageBuilder";

interface Props {
  urls: ExploreUrl[];
  car?: string;
  newsletterConsented?: boolean;
  email: string;
  dealer?: DealershipResponsibilityArea;
  receiptText: string;
  brandCode: string;
  formType: "contact" | "testdrive" | "offer" | "newsletter";
  contactTopic?: string;
  carUrls?: ExploreUrl[] | null;
  carInfo: CarInfo | null;
  image?: any;
}

const Receipt = (props: Props) => {
  const {
    urls,
    car,
    newsletterConsented,
    email,
    dealer,
    receiptText,
    brandCode,
    formType,
    contactTopic,
    carUrls,
    carInfo,
    image,
  } = props;

  const checkVPVtitle = () => {
    if (formType === "testdrive") {
      return `Kvittering | Prøvekjøring på ${getBrand(brandCode)} ${car} `;
    } else if (formType === "contact") {
      return `Kvittering | Kontakt oss`;
    } else if (formType === "offer") {
      return `Kvittering | Tilbud på ${getBrand(brandCode)} ${car} `;
    } else if (formType === "newsletter") {
      return `Kvittering | Nyhetsbrev`;
    } else {
      return "Noe gikk feil";
    }
  };

  const checkVPVpath = () => {
    if (formType === "testdrive") {
      return "/book-provekjoring/kvittering";
    } else if (formType === "contact") {
      return "/kontakt-oss/kvittering";
    } else if (formType === "offer") {
      return "/tilbud/kvittering";
    } else if (formType === "newsletter") {
      return "/nyhetsbrev/kvittering"
    } else {
      return "Noe gikk feil";
    }
  };

  const gtmCar: GTMCar = {
    brand: getBrand(brandCode),
    model: carInfo?.modelSeries as string,
    trim: carInfo?.carlineName as string,
    fuel: carInfo?.fuel as string,
    car_type: carInfo?.chassis as string,
    segment: carInfo?.segment as string,
    carline_code: carInfo?.carlineCode as string ?? "empty",
  };
  useEffect(() => {
    const data: any =
      checkVPVtitle() === "Kvittering | Kontakt oss"
        ? {
          event: "vpv",
          vpv_path: checkVPVpath(),
          vpv_title: checkVPVtitle(),
          topic: contactTopic,
        }
        : {
          event: "vpv",
          vpv_path: checkVPVpath(),
          vpv_title: checkVPVtitle(),
          topic: contactTopic,
          car: gtmCar,
        };
    if (formType === "contact" || formType === "testdrive" || formType === "offer") {
      data.newsLetterConsented = newsletterConsented
    }
    sendToGTM(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ReceiptLayout>
      {formType === "testdrive" && (
        <>
          {brandCode === "OPE" ? (
            <>
              <h1 className="title-h1-OpelLight">Prøvekjøring av </h1>
              <HeadingContainer>
                <h1 className="title-h1-OpelBold">
                  {getBrand(brandCode)} {car} bestilt!
                </h1>
                <img
                  alt="Slash.png"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAoCAYAAAB0HkOaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGuSURBVHgBxZjLUcMwEIZ/mcAkwyUHuDsd0AGUkBLSAXQAdEA6gA5CBbiEdEDOPIbcciDjZUNeq4ljW1rJ/i6xrId3/uibRDZQsqB+CnTeoWY5SKAkx8kDlCSg556Zz1TF/FC/zwtcQ4lBMl4XpaCL0yEvlUIBAdMz8zlVF8Nf0j2UGJjx/tqTBV3c8McbVNCsZ74H25Z3MgS6gxJOZWK3PQip88qibcsrmZA62/e8FtLrnMO8FKzrxoIuRyF07pmvDNpiQuts33cghs4Sp2Ry0AhK+IHZsb7aycTSWVI7mVg62/21F4qjs6SDGqx1phQq/jduVjaiZjJ6nflRj1UjKjdwbJ0llcnE1llSmkwTOktKk2lCZ3tseWd0nSVH1W5KZ0lJMs3oLClMZqNzChU062I5cZlRmEwonY2Zz13mHKjdtM6Sg2RC6Mx/K19dCyksJszZGU/wwNrAbegssZLhU+It1LjpbM3cXqx05nivoIDnsz2/GTzZFRNCZwOa+Gzc/Xy0q7MkWVfUCbBXkGkK2RXDG3cIJfnmVZiqmBBn55XO5+bD6XeosJi2dZb8Aef4sLEchV9aAAAAAElFTkSuQmCC"
                />
              </HeadingContainer>
            </>
          ) : (
            <h1>
              Prøvekjøring av {brandCode === "DS" ? "" : getBrand(brandCode)} {car} bestilt!
            </h1>
          )}
          <ReceiptTextContainer>
            <p>
              Takk for påmeldingen til prøvekjøring! Du vil bli kontaktet av {dealer?.DealerName} innen kort tid for å avtale tidspunkt.
            </p>
            <p>
              Du vil motta en bekreftelse på e-post på at vi har mottatt din forespørsel.
            </p>
            {receiptText && <p>{receiptText}</p>}
          </ReceiptTextContainer>
        </>
      )}
      {formType === "contact" && (
        <>
          {brandCode === "OPE" ? (
            <>
              <h1 className="title-h1-OpelLight">Beskjed</h1>
              <HeadingContainer>
                <h1 className="title-h1-OpelBold">sendt!</h1>
                <img
                  alt="Slash.png"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAoCAYAAAB0HkOaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGuSURBVHgBxZjLUcMwEIZ/mcAkwyUHuDsd0AGUkBLSAXQAdEA6gA5CBbiEdEDOPIbcciDjZUNeq4ljW1rJ/i6xrId3/uibRDZQsqB+CnTeoWY5SKAkx8kDlCSg556Zz1TF/FC/zwtcQ4lBMl4XpaCL0yEvlUIBAdMz8zlVF8Nf0j2UGJjx/tqTBV3c8McbVNCsZ74H25Z3MgS6gxJOZWK3PQip88qibcsrmZA62/e8FtLrnMO8FKzrxoIuRyF07pmvDNpiQuts33cghs4Sp2Ry0AhK+IHZsb7aycTSWVI7mVg62/21F4qjs6SDGqx1phQq/jduVjaiZjJ6nflRj1UjKjdwbJ0llcnE1llSmkwTOktKk2lCZ3tseWd0nSVH1W5KZ0lJMs3oLClMZqNzChU062I5cZlRmEwonY2Zz13mHKjdtM6Sg2RC6Mx/K19dCyksJszZGU/wwNrAbegssZLhU+It1LjpbM3cXqx05nivoIDnsz2/GTzZFRNCZwOa+Gzc/Xy0q7MkWVfUCbBXkGkK2RXDG3cIJfnmVZiqmBBn55XO5+bD6XeosJi2dZb8Aef4sLEchV9aAAAAAElFTkSuQmCC"
                />
              </HeadingContainer>
            </>
          ) : (
            <h1>Beskjed sendt!</h1>
          )}

          <ReceiptTextContainer>
            <p>
              Du vil normalt motta ett svar fra {dealer?.DealerName} innen 1-2
              arbeidsdager.
            </p>
            {receiptText && <p>{receiptText}</p>}
            {image && (
              <ImageContainer>
                <img
                  src={urlFor(image)
                    .height(875)
                    .auto("format")
                    .url()
                    ?.toString()}
                  alt={image.alttext ?? "Bil-logo"}
                />
              </ImageContainer>
            )}
          </ReceiptTextContainer>
        </>
      )}
      {formType === "newsletter" && (
        <>
          {brandCode === "OPE" ? (
            <>
              <h1 className="title-h1-OpelLight">Takk for din</h1>
              <HeadingContainer>
                <h1 className="title-h1-OpelBold">påmelding!</h1>
                <img
                  alt="Slash.png"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAoCAYAAAB0HkOaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGuSURBVHgBxZjLUcMwEIZ/mcAkwyUHuDsd0AGUkBLSAXQAdEA6gA5CBbiEdEDOPIbcciDjZUNeq4ljW1rJ/i6xrId3/uibRDZQsqB+CnTeoWY5SKAkx8kDlCSg556Zz1TF/FC/zwtcQ4lBMl4XpaCL0yEvlUIBAdMz8zlVF8Nf0j2UGJjx/tqTBV3c8McbVNCsZ74H25Z3MgS6gxJOZWK3PQip88qibcsrmZA62/e8FtLrnMO8FKzrxoIuRyF07pmvDNpiQuts33cghs4Sp2Ry0AhK+IHZsb7aycTSWVI7mVg62/21F4qjs6SDGqx1phQq/jduVjaiZjJ6nflRj1UjKjdwbJ0llcnE1llSmkwTOktKk2lCZ3tseWd0nSVH1W5KZ0lJMs3oLClMZqNzChU062I5cZlRmEwonY2Zz13mHKjdtM6Sg2RC6Mx/K19dCyksJszZGU/wwNrAbegssZLhU+It1LjpbM3cXqx05nivoIDnsz2/GTzZFRNCZwOa+Gzc/Xy0q7MkWVfUCbBXkGkK2RXDG3cIJfnmVZiqmBBn55XO5+bD6XeosJi2dZb8Aef4sLEchV9aAAAAAElFTkSuQmCC"
                />
              </HeadingContainer>
            </>
          ) : (
            <h1>Takk for din påmelding!</h1>
          )}

          <ReceiptTextContainer>
            <p>
              Vi gleder oss til å holde deg oppdatert med månedlige nyheter, kampanjer og produktinformasjon fra {getBrand(brandCode)}. Du kan når som helst melde deg av nyhetsbrevet ved å klikke på linken nederst i e-posten.
            </p>
            {receiptText && <p>{receiptText}</p>}
          </ReceiptTextContainer>
          {image && (
            <ImageContainer>
              <img
                src={urlFor(image)
                  .height(875)
                  .auto("format")
                  .url()
                  ?.toString()}
                alt={image.alttext ?? "Bil-logo"}
              />
            </ImageContainer>
          )}
        </>
      )}
      {formType === "offer" && (
        <>
          {brandCode === "OPE" ? (
            <>
              <h1 className="title-h1-OpelLight">
                Du har bedt om tilbud på en
              </h1>
              <HeadingContainer>
                <h1 className="title-h1-OpelBold">
                  {getBrand(brandCode)} {car}!
                </h1>
                <img
                  alt="Slash.png"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAoCAYAAAB0HkOaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGuSURBVHgBxZjLUcMwEIZ/mcAkwyUHuDsd0AGUkBLSAXQAdEA6gA5CBbiEdEDOPIbcciDjZUNeq4ljW1rJ/i6xrId3/uibRDZQsqB+CnTeoWY5SKAkx8kDlCSg556Zz1TF/FC/zwtcQ4lBMl4XpaCL0yEvlUIBAdMz8zlVF8Nf0j2UGJjx/tqTBV3c8McbVNCsZ74H25Z3MgS6gxJOZWK3PQip88qibcsrmZA62/e8FtLrnMO8FKzrxoIuRyF07pmvDNpiQuts33cghs4Sp2Ry0AhK+IHZsb7aycTSWVI7mVg62/21F4qjs6SDGqx1phQq/jduVjaiZjJ6nflRj1UjKjdwbJ0llcnE1llSmkwTOktKk2lCZ3tseWd0nSVH1W5KZ0lJMs3oLClMZqNzChU062I5cZlRmEwonY2Zz13mHKjdtM6Sg2RC6Mx/K19dCyksJszZGU/wwNrAbegssZLhU+It1LjpbM3cXqx05nivoIDnsz2/GTzZFRNCZwOa+Gzc/Xy0q7MkWVfUCbBXkGkK2RXDG3cIJfnmVZiqmBBn55XO5+bD6XeosJi2dZb8Aef4sLEchV9aAAAAAElFTkSuQmCC"
                />
              </HeadingContainer>
            </>
          ) : (
            <h1>
              Du har bedt om tilbud {brandCode === "DS" ? "på " : `på en ${getBrand(brandCode)}`} {car}!
            </h1>
          )}

          <ReceiptTextContainer>
            <p>
              Takk for din interesse! Du vil bli kontaktet av {dealer?.DealerName} innen kort tid med mer informasjon og et skreddersydd tilbud.
            </p>
            <p>
              Du vil motta en bekreftelse på e-post på at vi har mottatt din forespørsel.
            </p>
            {receiptText?.length > 1 && <p>{receiptText}</p>}
            {image && (
              <ImageContainer>
                <img
                  src={urlFor(image)
                    .height(875)
                    .auto("format")
                    .url()
                    ?.toString()}
                  alt={image.alttext ?? "Bil-logo"}
                />
              </ImageContainer>
            )}
          </ReceiptTextContainer>
        </>
      )}
      <VerticalDivider />
      <ExploreMoreUrlContainer>
        <h2 className="h3">Utforsk mer</h2>
        {formType === "newsletter" ?
          <Inner>

          </Inner>
          :
          <Inner>
            {carUrls?.map((url) => (
              <ExploreMoreLink
                href={url.href}
                target="_blank"
                rel="noreferrer noopener"
                key={url._key}
              >
                {url.title}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 407.436 407.436"
                >
                  <path d="M112.814 0L91.566 21.178l181.946 182.54-181.946 182.54 21.248 21.178 203.055-203.718z" />
                </svg>
              </ExploreMoreLink>
            ))}
            {brandCode === "OPE"
              ? urls?.map((url) => (
                <ExploreMoreLinkOpel
                  href={url.href}
                  target="_blank"
                  rel="noreferrer noopener"
                  key={url._key}
                >
                  <img
                    alt="Chevron.svg"
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDcgMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNi4zNjU4MyA3LjY5MDg4SDMuMzk4MzhMMC40NTMxMjUgMC43OTA4ODJIMy40MjYxMkw2LjM2NTgzIDcuNjkwODhaTTYuMzY1ODMgNy42OTA4OEgzLjM5ODM4TDAuNDUzMTI1IDE0LjU5MDlIMy40MjYxMkw2LjM2NTgzIDcuNjkwODhaIiBmaWxsPSIjRjdGRjE0Ii8+Cjwvc3ZnPgo="
                  />
                  {url.title}
                </ExploreMoreLinkOpel>
              ))
              : urls?.map((url) => (
                <ExploreMoreLink
                  href={url.href}
                  target="_blank"
                  rel="noreferrer noopener"
                  key={url._key}
                >
                  {url.title}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 407.436 407.436"
                  >
                    <path d="M112.814 0L91.566 21.178l181.946 182.54-181.946 182.54 21.248 21.178 203.055-203.718z" />
                  </svg>
                </ExploreMoreLink>
              ))}
          </Inner>}
      </ExploreMoreUrlContainer>
    </ReceiptLayout>
  );
};

export default Receipt;
