import styled from "styled-components";
import { InfoContainer } from "../../components/car-card/styles";
import { device } from "../../styles/breakpoints";

export const NewsLetterFormContainer = styled.div`
  margin-top: var(--sp48);
  textarea {
    height: 200px;
    padding: var(--sp8) var(--sp16);
    max-width: calc(100vw - var(--sp48));
  }
`;

export const SubmitButtonNewsLetter = styled.button`
  margin: 0;
  border-radius: 0;
  border: none;
  padding: 18px 22px;
  cursor: pointer;
  margin-top: var(--sp16);
`;
export const NewsLetterIngress = styled.p`
  margin-bottom: var(--sp64);
  max-width: calc(100vw - var(--sp48));
`;

export const SubmitButtonNewsLetterOpel = styled(SubmitButtonNewsLetter)`
  display: flex;
  flex-direction: row;
  img {
    height: 22px;
    margin-right: 7px;
    width: 7px;
  }
`;
export const CarInfoContainer = styled.section`
  width: 100%;
  height: max-content;
  grid-row: 2;
  grid-column: 2;

  img {
    max-height: 300px;
  }

  @media ${device.tabletMax} {
    grid-column: 1;
    grid-row: 1;
  }
`;
export const ImageInfoContainer = styled.div`
  max-height: 361px;
  display: flex;
  flex-direction: column;
  img {
    margin: var(--sp24) auto;
    max-height: 275px;
    height: 100%;
  }

  ${InfoContainer} {
    height: 60px;
  }
`;

export const PageLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content 1fr;

  @media ${device.tabletMax} {
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content min-content;
  }
`;
export const Container = styled.div`
  grid-row: 2;
  grid-column: 1;
  @media ${device.tabletMax} {
    grid-column: 1;
    grid-row: 3;
    padding: 0 var(--sp24);
  }
`;

export const TitleContainer = styled.div`
  grid-row: 1;
  grid-column: 1 / -1;
  @media ${device.tabletMax} {
    grid-column: 1;
    grid-row: 2;
    margin-top: var(--sp32);
  }
`;
export const NewsLetterH1 = styled.h1`
  margin-bottom: var(--sp16);
  word-break: break-word;
  max-width: calc(100vw - var(--sp48));
  @media ${device.tabletMax} {
    margin-top: var(--sp32);
  }
`;