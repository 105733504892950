import styled from "styled-components";
import { InfoContainer } from "../../../components/car-card/styles";
import { device } from "../../../styles/breakpoints";

export const PageLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content 1fr;

  @media ${device.tabletMax} {
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content min-content;
  }
`;
export const Container = styled.div`
  grid-row: 2;
  grid-column: 1;
  @media ${device.tabletMax} {
    grid-column: 1;
    grid-row: 3;
    padding: 0 var(--sp24);
  }
`;

export const TitleContainer = styled.div`
  grid-row: 1;
  grid-column: 1 / -1;
  @media ${device.tabletMax} {
    grid-column: 1;
    grid-row: 2;
    padding: 0 var(--sp24);
    margin-top: var(--sp32);
  }
`;

export const CarInfoContainer = styled.section`
  width: 100%;
  height: max-content;
  grid-row: 2;
  grid-column: 2;

  img {
    max-height: 300px;
  }

  @media ${device.tabletMax} {
    grid-column: 1;
    grid-row: 1;
  }
`;

export const ImageInfoContainer = styled.div`
  max-height: 361px;
  background: #fff;
  display: flex;
  flex-direction: column;
  img {
    margin: var(--sp24) auto;
    max-height: 275px;
    height: auto;
    }

  ${InfoContainer} {
    height: 60px;
  }
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;

  label {
    word-break: break-word;
    margin-bottom: var(--sp8);
  }

  input {
    padding: 0.5rem 1rem;
    /* font-size: 1.2rem; */
    max-width: calc(100vw - var(--sp48));
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
`;

export const NewsLetterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: var(--sp32) 0;

  .no-m {
    margin: 0 !important;
  }

  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-right: var(--sp16);
  }
`;

export const Abbr = styled.abbr`
  margin-left: 0.4em;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  margin: 1rem;
`;

export const TestDriveH1 = styled.h1`
  text-transform: uppercase;
  margin-top: var(--sp80);
  margin-bottom: var(--sp16);
  word-break: break-word;
  max-width: calc(100vw - var(--sp48));
  @media ${device.tabletMax} {
    margin-top: var(--sp32);
  }
`;

export const TestDriveIngress = styled.p`
  margin-bottom: var(--sp64);
  max-width: calc(100vw - var(--sp48));
`;

export const SubmitButtonTestDrive = styled.button`
  margin: 0;
  border-radius: 0;
  border: none;
  padding: 18px 22px;
  cursor: pointer;
  margin-top: var(--sp32);
`;

export const FieldErrorText = styled.p`
  margin-top: var(--sp8);
`;

export const TestDriveBackButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  text-align: start;
  text-decoration: underline;
  cursor: pointer;
  margin-top: var(--sp24);
  display: block;
  margin-bottom: var(--sp24);
  @media ${device.tabletMax} {
    &.remove {
      display: none;
    }
  }
`;

export const CustomLink = styled.a`
  color: black;
`;

export const TestDriveLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: var(--sp64);
  margin-top: var(--sp80);
  word-break: break-word;
  max-width: calc(100vw - var(--sp48));
  @media ${device.tabletMax} {
    margin-top: var(--sp32);
  }
`;

export const TilbudLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: var(--sp64);
  margin-top: var(--sp80);
  word-break: break-word;
  max-width: calc(100vw - var(--sp48));
  @media ${device.tabletMax} {
    margin-top: var(--sp32);
  }
`;

export const Brand = styled.h1`
  text-transform: uppercase;
  word-break: break-word;
`;

export const SubmitButtonTestDriveOpel = styled(SubmitButtonTestDrive)`
  display: flex;
  flex-direction: row;
  img {
    height: 22px;
    margin-right: 7px;
    width: 7px;
  }
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
