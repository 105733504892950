import styled from "styled-components";

export const ChosenDealerContainer = styled.div`
  border: 1px solid black;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: var(--sp24);
  row-gap: var(--sp8);
  justify-content: center;
  align-items: center;
  padding: var(--sp16);
  background: #fff;
  margin-top: var(--sp8) !important;

  svg {
    grid-column: 1;
    grid-row: 1 / -1;
  }
  h6 {
    grid-column: 2;
    grid-row: 1;
    font-weight: 100;
  }
  p {
    grid-column: 2;
    grid-row: 2;
  }
`;

export const PickOtherDealerButton = styled.button`
  background: none;
  margin: 0;
  margin-top: var(--sp16);
  padding: 0;
  border: none;
  border-radius: 0;
  text-align: start;
  cursor: pointer;
  margin-bottom: var(--sp16);
  text-decoration: underline;
`;

export const Dropdown = styled.select`
  background: #fff;
  border-radius: 0;
  height: 50px;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding: 0 var(--sp32) 0 var(--sp16);
  max-width: calc(100vw - var(--sp48));
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 11px;

  option {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Error = styled.p`
  color: red;
  padding: var(--sp16) 0;
`;
