import styled from "styled-components";
import { device } from "../../styles/breakpoints";

export const NewsLetterLayout = styled.section`
  margin-top: var(--sp80);
  @media ${device.mobileMax} {
    padding: 0 var(--sp24);
    margin-top: var(--sp48);
  }
`;

export const NewsLetterOpel = styled.div`
  display: flex; 
  flex-direction: row;
}
`;
