export const getBrand = (v: string) => {
  switch (v) {
    case "PEU":
      return "Peugeot";
    case "CIT":
      return "Citroen";
    case "DS":
      return "DS";
    case "OPE":
      return "Opel";
    case "JEE":
      return "Jeep";
    case "FIA":
      return "Fiat";
    case "FIP":
      return "Fiat";
    case "ALF":
      return "Alfa";
    default:
      return "Noe gikk galt ved konvertering fra brandCode til brandname";
  }
};
