import styled from "styled-components";
import { device } from "../../styles/breakpoints";

export const CardContainer = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  max-width: 400px;
  position: relative;
  height: max-content;
  width: 100%;
  background: #fff;
  @media ${device.mobileMax} {
    max-width: 327px;
  }
  img {
    max-height: 150px;
    min-height: 150px;
    height: 100%;
    margin: var(--sp24) auto;
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    position: absolute;
    opacity: 0;
    left: -9999px;
    &::focus {
      border: 1px solid red;
    }
  }

  label {
    height: max-content;
    cursor: pointer;
  }
`;

export const TestDriveTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 46px;
  cursor: pointer;
`;

export const MissingImageContainer = styled.div`
  height: 175px;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: var(--sp24);
  margin-bottom: var(--sp16);
  height: 40px;

  span {
    text-transform: uppercase;
  }

  @media ${device.mobileMax} {
    margin-left: var(--sp16);
  }
`;

export const Divider = styled.div`
  margin: 0 var(--sp8);
`;
