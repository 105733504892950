import styled from "styled-components";
import FiatLogo from "../../logos/fiat";

const HeaderLink = styled.a`
  font-size: 12px;
`;

const FiatHeader = () => {
  return (
    <>
      <a href="https://www.fiat.no/">
        <FiatLogo />
      </a>
      <HeaderLink
        href="https://www.fiat.no/"
        rel="noopener noreferrer"
        target="_blank"
      >
        TIL FIAT.NO
      </HeaderLink>
    </>
  );
};

export default FiatHeader;
