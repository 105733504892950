import styled from "styled-components";
import OpelLogo from "../../logos/opel";

const HeaderLink = styled.a`
  font-family: "OpelBold";
  font-size: 12px;
`;

const LogoContainer = styled.div`
@media only screen and (min-width: 600px) {
    margin: 0 auto;
  }
}`;

const OpelHeader = () => {
  return (
    <>
      <a href="https://www.opel.no/">
        <LogoContainer>
          <OpelLogo />
        </LogoContainer>
      </a>
      <HeaderLink
        href="https://www.opel.no/"
        rel="noopener noreferrer"
        target="_blank"
      >
        TIL OPEL.NO
      </HeaderLink>
    </>
  );
};

export default OpelHeader;
