import {
  DealershipResponsibilityArea,
  OfferPageFormValues,
} from "../../../../common/typedefs";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { isObject } from "lodash";
import { createContext, useContext, useEffect, useState } from "react";
import { CarInfo } from "../test-drive";
import { FieldErrorText } from "../../forms/test-drive/personalia/styles";
import CarSelection from "../../forms/test-drive/car-selection";
import TestDrivePersonalia from "../../forms/test-drive/personalia";
import Receipt from "../../components/receipt";
import { sendToGTM } from "../../lib/sendToGTM";
import { getBrand } from "../../lib/getBrandFromCode";
import { GTMCar } from "../../../../common/typedefs";
import { useLocation } from "react-router";
// @ts-ignore
import queryString from "query-string";
import { AllDataContext } from "../../lib/dataContext";
import { TilbudContainer, TildbudLayout } from "./styles";
import { buildMaxCharMessage, yupPhoneValidation } from "../../lib/phoneValidation";


const initialValues: OfferPageFormValues = {
  car: "",
  fname: "",
  lname: "",
  email: "",
  phone: "",
  message: "",
  newsletter: false,
  dealer: "",
};

export const OfferPageContext = createContext({
  step: 0,
  setStep: (v: number) => {},
  selectedCarCode: "",
  setSelectedCarCode: (v: string) => {},
  selectedCar: "",
  setSelectedCar: (v: string) => {},
  setCarInfo: (v: any) => {},
});

export const OfferFormValidation = Yup.object().shape({
  fname: Yup.string().max(50, buildMaxCharMessage(50)).required("Påkrevd"),
  lname: Yup.string().max(100, buildMaxCharMessage(100)).required("Påkrevd"),
  email: Yup.string().max(100, buildMaxCharMessage(100)).email("Ugyldig epost").required("Påkrevd"),
  phone: yupPhoneValidation,
  dealer: Yup.object().required("Påkrevd"),
});

const OfferPage = () => {
  const { cars, offerTexts, brandCode, testDriveTexts } =
    useContext(AllDataContext);

  const [step, setStep] = useState<number>(0);
  const [selectedCar, setSelectedCar] = useState<string>("");
  const [selectedCarCode, setSelectedCarCode] = useState<string>("");
  const [carInfo, setCarInfo] = useState<CarInfo | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [trimCode, setTrimCode] = useState<string | null>(null);

  const { search } = useLocation();
  const { modelid }: { modelid: string } = queryString.parse(search);
  const { trim }: { trim: string } = queryString.parse(search);

  const contextValue = {
    step,
    setStep,
    selectedCar,
    setSelectedCar,
    selectedCarCode,
    setSelectedCarCode,
    carInfo,
    setCarInfo,
  };
  const gtmCar: GTMCar = {
    brand: getBrand(brandCode),
    model: carInfo?.modelSeries as string,
    trim: selectedCar,
    fuel: carInfo?.fuel as string,
    car_type: carInfo?.chassis as string,
    segment: carInfo?.segment as string,
  };
  useEffect(() => {
    if (trim) {
      setTrimCode(trim);
    }
  }, [trim]);

  useEffect(() => {
    if (modelid) {
      const filteredCars = cars.filter((car) => car.IsAvailableForOffer);
      const carFromQueryParam = filteredCars.find(
        (x) => x.carlineCode.toLowerCase() === modelid.toLowerCase()
      );
      if (carFromQueryParam) {
        setSelectedCar(carFromQueryParam.name);
        setCarInfo({
          chassis: carFromQueryParam.chassis,
          fuel: carFromQueryParam.fuel,
          imageUrl: carFromQueryParam.imageUrl,
          image: carFromQueryParam.image,
          carlineCode: carFromQueryParam.carlineCode,
          carlineName: carFromQueryParam.carlineName,
          segment: carFromQueryParam.segment,
          modelSeries: carFromQueryParam.modelseries,
          receiptLinks: carFromQueryParam.receiptLinks,
        });
        setStep(1);
      }
    }
  }, [modelid, cars]);

  return (
    <OfferPageContext.Provider value={contextValue}>
      <Formik
        validationSchema={OfferFormValidation}
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          if (isObject(values.dealer)) {
            setError(false);
            const data = JSON.stringify({
              dealerNo: values.dealer.DealerNo,
              firstName: values.fname,
              lastName: values.lname,
              newsletterConsent: values.newsletter,
              email: values.email,
              phone: values.phone,
              carlineCode: carInfo?.carlineCode,
              carlineName: carInfo?.carlineName,
              trimCode: trimCode,
              dealerEmail: values.dealer.Email,
            });
            try {
              setError(false);
              await fetch("api/boscrm/offer", {
                method: "post",
                headers: {
                  "Content-Type": "application/json",
                },
                body: data,
              });
              setStep(2);
              actions.setSubmitting(false);
            } catch (error) {
              sendToGTM({
                event: "error",
                field_name: "Send to BOSCRM | Offer",
                error_code: "400",
                error_text: error,
              });
            }
          } else {
            setError(true);
          }
        }}
      >
        {({ values, isSubmitting, errors, touched, isValid }) => (
          <Form>
            {error && (
              <FieldErrorText>
                Noe gikk galt, vennligst prøv igjen
              </FieldErrorText>
            )}
            {step === 0 && (
              <>
                {brandCode === "OPE" ? (
                  <TildbudLayout>
                    <h1 className="title-h1-OpelLight">Motta</h1>
                    <TilbudContainer>
                      <h1 className="title-h1-OpelBold">Tilbud</h1>
                      <img
                        alt="Slash.png"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAoCAYAAAB0HkOaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGuSURBVHgBxZjLUcMwEIZ/mcAkwyUHuDsd0AGUkBLSAXQAdEA6gA5CBbiEdEDOPIbcciDjZUNeq4ljW1rJ/i6xrId3/uibRDZQsqB+CnTeoWY5SKAkx8kDlCSg556Zz1TF/FC/zwtcQ4lBMl4XpaCL0yEvlUIBAdMz8zlVF8Nf0j2UGJjx/tqTBV3c8McbVNCsZ74H25Z3MgS6gxJOZWK3PQip88qibcsrmZA62/e8FtLrnMO8FKzrxoIuRyF07pmvDNpiQuts33cghs4Sp2Ry0AhK+IHZsb7aycTSWVI7mVg62/21F4qjs6SDGqx1phQq/jduVjaiZjJ6nflRj1UjKjdwbJ0llcnE1llSmkwTOktKk2lCZ3tseWd0nSVH1W5KZ0lJMs3oLClMZqNzChU062I5cZlRmEwonY2Zz13mHKjdtM6Sg2RC6Mx/K19dCyksJszZGU/wwNrAbegssZLhU+It1LjpbM3cXqx05nivoIDnsz2/GTzZFRNCZwOa+Gzc/Xy0q7MkWVfUCbBXkGkK2RXDG3cIJfnmVZiqmBBn55XO5+bD6XeosJi2dZb8Aef4sLEchV9aAAAAAElFTkSuQmCC"
                      />
                    </TilbudContainer>
                  </TildbudLayout>
                ) : (
                  <h1 className="title-h1">Få tilbud</h1>
                )}
                <CarSelection pageType="offer" />
              </>
            )}
            {step === 1 && (
              <TestDrivePersonalia
                submitting={isSubmitting}
                texts={offerTexts}
                isValid={isValid}
                errors={errors}
                touched={touched}
                carInfo={carInfo}
                pageType="offer"
              />
            )}
            {step === 2 && (
              <Receipt
                urls={testDriveTexts.exploreURLs}
                car={carInfo?.carlineName ? carInfo.carlineName.trim() : values.car}
                carUrls={carInfo?.receiptLinks ?? null}
                image={carInfo?.image}
                email={values.email}
                dealer={values.dealer as DealershipResponsibilityArea}
                receiptText={offerTexts.receiptText}
                brandCode={brandCode}
                carInfo={carInfo}
                formType="offer"
                newsletterConsented={values.newsletter}
              />
            )}
          </Form>
        )}
      </Formik>
    </OfferPageContext.Provider>
  );
};

export default OfferPage;
