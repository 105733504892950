import { LoadingContainer } from "./styles";

const LoadingSpinner = () => {
  return (
    <LoadingContainer>
      <div className="loader">Loading...</div>
    </LoadingContainer>
  );
};

export default LoadingSpinner;
