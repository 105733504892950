import PeugeotLogo from "../../logos/peugeot";

export const PeugeotHeader = () => {
  return (
    <>
      <a href="https://www.peugeot.no/">
        <PeugeotLogo />
      </a>
      <a
        href="https://www.peugeot.no/"
        rel="noopener noreferrer"
        target="_blank"
      >
        Gå til peugeot.no
      </a>
    </>
  );
};

export default PeugeotHeader;
