import styled from "styled-components";
import FPLogo from "../../logos/fp";

const HeaderLink = styled.a`
  font-size: 12px;
`;

const FPHeader = () => {
  return (
    <>
      <a href="https://www.fiatprofessional.com/no">
        <FPLogo />
      </a>
      <HeaderLink
        href="https://www.fiatprofessional.com/no"
        rel="noopener noreferrer"
        target="_blank"
      >
        TIL FIATPROFESSIONAL.COM/NO
      </HeaderLink>
    </>
  );
};

export default FPHeader;
