import { Field, FormikErrors, FormikTouched, useField } from "formik";
import React, { useContext, useEffect } from "react";
import { IContactUsPageFormValues } from "../../../../common/typedefs";
import DealerLookup from "../../components/dealer-lookup";
import { Dropdown } from "../../components/dealer-lookup/styles";
import { urlFor } from "../../lib/imageBuilder";
import {
  Abbr,
  CustomLink,
  FieldContainer,
  FieldErrorText,
  NewsLetterContainer,
} from "../../forms/test-drive/personalia/styles";
import { BlockContentContainer, InputContainer } from "../../styles/containers";
import {
  CarInfoContainer,
  ContactUsIngress,
  Container,
  ImageInfoContainer,
  PageLayout,
  SubmitButtonContactUs,
  SubmitButtonContactUsOpel,
  TempSpacingContainer,
  TitleContainer,
} from "./styles";
//@ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { sendToGTM } from "../../lib/sendToGTM";
import { AllDataContext } from "../../lib/dataContext";
import { ContactUsOpel } from "../../pages/contact-us/styles";
interface Data {
  isSubmitting: boolean;
  errors: FormikErrors<IContactUsPageFormValues>;
  touched: FormikTouched<IContactUsPageFormValues>;
  isValid: boolean;
}

const ContactUsForm = ({ isSubmitting, errors, touched }: Data) => {
  const { contactUsTexts, testDriveTexts, brandCode } =
    useContext(AllDataContext);
  const [, , helpers] = useField("topic");
  const { setValue } = helpers;

  const updateTopic = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    sendToGTM({
      event: "vpv",
      vpv_title: "Kontakt oss",
      vpv_path: "/kontakt-oss/personopplysninger",
      form_name: "kontakt",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const serializers = {
    marks: {
      link: ({ mark, children }: any) => {
        return (
          <CustomLink href={mark.href} target="_blank" rel="noreferrer">
            {children}
          </CustomLink>
        );
      },
    },
  };

  return (
    <PageLayout>
      <TitleContainer>
        {brandCode === "OPE" ? (
          <ContactUsOpel>
            <h1>KONTAKT OSS</h1>
            <img
              alt=""
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAoCAYAAAB0HkOaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGuSURBVHgBxZjLUcMwEIZ/mcAkwyUHuDsd0AGUkBLSAXQAdEA6gA5CBbiEdEDOPIbcciDjZUNeq4ljW1rJ/i6xrId3/uibRDZQsqB+CnTeoWY5SKAkx8kDlCSg556Zz1TF/FC/zwtcQ4lBMl4XpaCL0yEvlUIBAdMz8zlVF8Nf0j2UGJjx/tqTBV3c8McbVNCsZ74H25Z3MgS6gxJOZWK3PQip88qibcsrmZA62/e8FtLrnMO8FKzrxoIuRyF07pmvDNpiQuts33cghs4Sp2Ry0AhK+IHZsb7aycTSWVI7mVg62/21F4qjs6SDGqx1phQq/jduVjaiZjJ6nflRj1UjKjdwbJ0llcnE1llSmkwTOktKk2lCZ3tseWd0nSVH1W5KZ0lJMs3oLClMZqNzChU062I5cZlRmEwonY2Zz13mHKjdtM6Sg2RC6Mx/K19dCyksJszZGU/wwNrAbegssZLhU+It1LjpbM3cXqx05nivoIDnsz2/GTzZFRNCZwOa+Gzc/Xy0q7MkWVfUCbBXkGkK2RXDG3cIJfnmVZiqmBBn55XO5+bD6XeosJi2dZb8Aef4sLEchV9aAAAAAElFTkSuQmCC"
            />
          </ContactUsOpel>
        ) : (
          <h1>KONTAKT OSS</h1>
        )}
        <ContactUsIngress>{contactUsTexts?.ingress}</ContactUsIngress>
      </TitleContainer>
      <Container>
        <InputContainer>
          <FieldContainer>
            <label className="p2" htmlFor="fname">
              Fornavn
              <Abbr title="Påkrevd">*</Abbr>
            </label>
            <Field id="fname" name="fname" required autoComplete="given-name" />
            {touched.fname && errors.fname ? (
              <FieldErrorText>{errors.fname}</FieldErrorText>
            ) : null}
          </FieldContainer>

          <FieldContainer>
            <label className="p2" htmlFor="lname">
              Etternavn
              <Abbr title="Påkrevd">*</Abbr>
            </label>
            <Field
              id="lname"
              name="lname"
              required
              autoComplete="family-name"
            />
            {touched.lname && errors.lname ? (
              <FieldErrorText>{errors.lname}</FieldErrorText>
            ) : null}
          </FieldContainer>

          <FieldContainer>
            <label className="p2" htmlFor="email">
              E-post
              <Abbr title="Påkrevd">*</Abbr>
            </label>
            <Field id="email" name="email" required autoComplete="email" />
            {touched.email && errors.email ? (
              <FieldErrorText>{errors.email}</FieldErrorText>
            ) : null}
          </FieldContainer>

          <FieldContainer>
            <label className="p2" htmlFor="phone">
              Mobilnummer
              <Abbr title="Påkrevd">*</Abbr>
            </label>
            <Field
              id="phone"
              name="phone"
              type="tel"
              required
              autoComplete="tel"
              inputMode="numeric"
            />
            {touched.phone && errors.phone ? (
              <FieldErrorText>{errors.phone}</FieldErrorText>
            ) : null}
          </FieldContainer>

          <FieldContainer>
            <label className="p2" htmlFor="topic">
              Tema
              <Abbr title="Påkrevd">*</Abbr>
            </label>
            <Dropdown name="topic" onChange={updateTopic}>
              <option value="">Velg ett tema</option>
              <option value="Service og reperasjon">
                Service og reperasjon
              </option>
              <option value="Kjøpe/selge bruktbil">Kjøpe/selge bruktbil</option>
              <option value="Annet">Annet</option>
              <option value="Deler og tilbehør">Deler og tilbehør</option>
            </Dropdown>
            {touched.topic && errors.topic ? (
              <FieldErrorText>{errors.topic}</FieldErrorText>
            ) : null}
          </FieldContainer>

          <FieldContainer>
            <label className="p2" htmlFor="message">
              Din beskjed
              <Abbr title="Påkrevd">*</Abbr>
            </label>
            <Field id="message" name="message" as="textarea" />
            {touched.message && errors.message ? (
              <FieldErrorText>{errors.message}</FieldErrorText>
            ) : null}
          </FieldContainer>
          <DealerLookup noDealerFoundText={contactUsTexts?.noDealerFoundText} />

          {/* New lead api v3 inquiry request doesn't handle newsletter. Just uncommenting in case they add support for it later. */}
          {/* <NewsLetterContainer className="newsletter">
            {contactUsTexts.checkboxText && (
              <>
                <div>
                  <Field
                    id="newsletter"
                    name="newsletter"
                    type="checkbox"
                  />
                </div>
                <div className="no-m">
                  <label htmlFor="newsletter">
                    <p className="p2">{contactUsTexts.checkboxText}</p>
                  </label>
                </div>
              </>
            )}
          </NewsLetterContainer> */}

          {/* Remove this if NewsLetterContainer gets uncommented again */}
          <TempSpacingContainer />

          {brandCode === "OPE" ? (
            <SubmitButtonContactUsOpel type="submit" disabled={isSubmitting}>
              <img
                alt="Chevron.svg"
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDcgMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNi4zNjU4MyA3LjY5MDg4SDMuMzk4MzhMMC40NTMxMjUgMC43OTA4ODJIMy40MjYxMkw2LjM2NTgzIDcuNjkwODhaTTYuMzY1ODMgNy42OTA4OEgzLjM5ODM4TDAuNDUzMTI1IDE0LjU5MDlIMy40MjYxMkw2LjM2NTgzIDcuNjkwODhaIiBmaWxsPSIjRjdGRjE0Ii8+Cjwvc3ZnPgo="
              />
              Finn ut mer
            </SubmitButtonContactUsOpel>
          ) : (
            <SubmitButtonContactUs type="submit" disabled={isSubmitting}>
              Send inn
            </SubmitButtonContactUs>
          )}

          {contactUsTexts.userAgreementText && (
            <BlockContentContainer>
              <BlockContent
                blocks={contactUsTexts.userAgreementText}
                serializers={serializers}
              />
            </BlockContentContainer>
          )}
        </InputContainer>
      </Container>
      <CarInfoContainer>
        {contactUsTexts.image && (
          <ImageInfoContainer>
            <img
              src={urlFor(contactUsTexts.image)
                .height(875)
                .auto("format")
                .url()
                ?.toString()}
              alt={contactUsTexts.image.alttext ?? "Bil"}
            />
          </ImageInfoContainer>
        )}
      </CarInfoContainer>
    </PageLayout>
  );
};

export default ContactUsForm;
