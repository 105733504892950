import { DealershipResponsibilityArea, GTMCar } from "../../../common/typedefs";

interface Props {
  event: string;
  vpv_path?: string;
  vpv_title?: string;
  group_variable?: string;
  car?: GTMCar;
  form_name?: string;
  field_name?: string;
  dealer?: DealershipResponsibilityArea;
  error_code?: string;
  error_text?: unknown;
  topic?: string;
}

export const sendToGTM = (data: Props) => {
  const {
    event,
    vpv_path,
    vpv_title,
    group_variable,
    car,
    form_name,
    field_name,
    dealer,
    error_code,
    error_text,
    topic,
  } = data;

  const test: Props = {
    event: event,
    vpv_path: vpv_path,
    vpv_title: vpv_title,
    group_variable: group_variable,
    car: car,
    form_name: form_name,
    field_name: field_name,
    dealer: dealer,
    error_code: error_code,
    error_text: error_text,
    topic: topic,
  };
  const objectWithNoUndefined = JSON.parse(JSON.stringify(test));
  //@ts-ignore
  window?.dataLayer?.push(objectWithNoUndefined);
};
