import { createContext, useEffect, useState } from "react";
import { AllSanityData } from "../../../common/typedefs";

export const AllDataContext = createContext<AllSanityData>({} as AllSanityData);

const DataContextProvider = ({ children }: any) => {
  const [data, setData] = useState<AllSanityData | null>(null);

  useEffect(() => {
    const getData = async () => {
      const brand = await fetch("api/sanity/data").then((res) => res.json());
      setData(brand);
    };
    getData();
  }, []);

  if (data === null) {
    return null;
  }

  return (
    <AllDataContext.Provider value={data}>{children}</AllDataContext.Provider>
  );
};

export default DataContextProvider;
