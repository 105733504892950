import { TableContainer } from "./styles";

interface Props {
  rows: Array<String>;
}

const Table = ({ rows }: Props) => (
  <TableContainer>
    {rows.map((row: any, index: number) =>
      index === 0 ? (
        <tr>
          {row.cells.map((cell: any) => (
            <th>{cell}</th>
          ))}
        </tr>
      ) : (
        <tr>
          {row.cells.map((cell: any) => (
            <td>{cell}</td>
          ))}
        </tr>
      )
    )}
  </TableContainer>
);
export default Table;
