import ContactUsForm from "../../forms/contact";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ContactUsLayout} from "./styles";
import { useContext, useState } from "react";
import {
  DealershipResponsibilityArea,
  IContactUsPageFormValues,
} from "../../../../common/typedefs";
import { isObject } from "lodash";
import { FieldErrorText } from "../../forms/test-drive/personalia/styles";
import Receipt from "../../components/receipt";
import { sendToGTM } from "../../lib/sendToGTM";
import { AllDataContext } from "../../lib/dataContext";
import { buildMaxCharMessage, yupPhoneValidation } from "../../lib/phoneValidation";

export const ContactUsValidation = Yup.object().shape({
  fname: Yup.string().max(50, buildMaxCharMessage(50)).required("Påkrevd"),
  lname: Yup.string().max(100, buildMaxCharMessage(100)) .required("Påkrevd"),
  email: Yup.string().max(100, buildMaxCharMessage(100)).email("Ugyldig epost").required("Påkrevd"),
  phone: yupPhoneValidation,
  message: Yup.string().max(1500, buildMaxCharMessage(1500)).required("Påkrevd"),
  dealer: Yup.object().required("Påkrevd"),
  topic: Yup.string().min(2).max(200, buildMaxCharMessage(200)).required("Påkrevd"),
});

const ContactUsPage = () => {
  const [step, setStep] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);

  const { contactUsTexts, brandCode } = useContext(AllDataContext);

  const initialValues: IContactUsPageFormValues = {
    fname: "",
    lname: "",
    email: "",
    phone: "",
    topic: "",
    message: "",
    postnr: 0,
    dealer: "",
    newsletter: false
  };

  return (
    <ContactUsLayout>
      <Formik
        validationSchema={ContactUsValidation}
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          if (isObject(values.dealer)) {
            setError(false);
            const data = JSON.stringify({
              dealerNo: values.dealer.DealerNo,
              firstName: values.fname,
              lastName: values.lname,
              topic: values.topic,
              email: values.email,
              phone: values.phone,
              message: values.message,
              dealerEmail: values.dealer.Email,
              newsletterConsent: values.newsletter
            });
            try {
              await fetch("/api/boscrm/contact", {
                method: "post",
                headers: {
                  "Content-Type": "application/json",
                },
                body: data,
              });
              actions.setSubmitting(false);
              setStep(1);
            } catch (error) {
              sendToGTM({
                event: "error",
                field_name: "Send to BOSCRM | Contact ",
                error_code: "400",
                error_text: error,
              });
            }
          } else {
            setError(true);
          }
        }}
      >
        {({ values, isSubmitting, errors, touched, isValid }) => (
          <Form>
            {error && (
              <FieldErrorText>
                Noe gikk galt, vennligst prøv på nytt
              </FieldErrorText>
            )}
            {step === 0 ? (
              <ContactUsForm
                isSubmitting={isSubmitting}
                errors={errors}
                touched={touched}
                isValid={isValid}
              />
            ) : (
              <Receipt
                urls={contactUsTexts.exploreURLs}
                email={values.email}
                dealer={values.dealer as DealershipResponsibilityArea}
                receiptText={contactUsTexts.receiptText}
                image={contactUsTexts.image}
                brandCode={brandCode}
                formType="contact"
                contactTopic={values.topic}
                carInfo={null}
                newsletterConsented={values.newsletter}
              />
            )}
          </Form>
        )}
      </Formik>
    </ContactUsLayout>
  );
};

export default ContactUsPage;
