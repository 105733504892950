import { useContext } from "react";
import { AllDataContext } from "../../../lib/dataContext";
import PeugeotLogo from "../../../logos/peugeot";
import {
  FooterLink,
  Inner,
  LinkContainer,
  PeugeotFooterContainer,
} from "./styles";

const PeugeotFooter = () => {
  const data = useContext(AllDataContext);

  const links = data.footerItems.footerLinks;

  return (
    <PeugeotFooterContainer>
      <Inner>
        <PeugeotLogo />
        <LinkContainer>
          {links?.map((link) => (
            <FooterLink
              className="p2"
              href={link.href}
              rel="noreferrer noopener"
              target="_blank"
              key={link._key}
            >
              {link.title}
            </FooterLink>
          ))}
        </LinkContainer>
      </Inner>
    </PeugeotFooterContainer>
  );
};

export default PeugeotFooter;
