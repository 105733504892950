import { createGlobalStyle } from "styled-components";

// @ts-ignore
import PeugeotRegular from "../fonts/Peugeot-font/PeugeotNew-Regular.otf";
// @ts-ignore
import PeugeotBold from "../fonts/Peugeot-font/PeugeotNew-Bold.otf";
// @ts-ignore
import CitroenRegular from "../fonts/Citroen-font/Citroen-Regular.otf";
// @ts-ignore
import CitroenBold from "../fonts/Citroen-font/Citroen-Bold.otf";
// @ts-ignore
import DSRegular from "../fonts/DS-font/DSAutomobilesRegular.otf";
// @ts-ignore
import DSBold from "../fonts/DS-font/DSAutomobilesBold.otf";
// @ts-ignore
import DSTitleMediumBold from "../fonts/DS-font/DSTitleMediumBold.otf";
// @ts-ignore
import OpelBold from "../fonts/Opel-font/OpelNextBold.otf";
// @ts-ignore
import OpelRegular from "../fonts/Opel-font/OpelNext.otf";
// @ts-ignore
import OpelLight from "../fonts/Opel-font/OpelNextLight.otf";
// @ts-ignore
import DSTitleMediumRegular from "../fonts/DS-font/DSTitleMediumRegular.otf";
// @ts-ignore
import RobotoRegular from "../fonts/Jeep-font/Roboto-Regular.ttf";
// @ts-ignore
import RobotoBold from "../fonts/Jeep-font/Roboto-Bold.ttf";
// @ts-ignore
import MontserratRegular from "../fonts/Jeep-font/Montserrat-Medium.otf";
// @ts-ignore
import OpenSans from "../fonts/Fiat-font/OpenSans-Regular.ttf";
// @ts-ignore
import OpenSansBold from "../fonts/Fiat-font/OpenSans-Bold.ttf";

import { PeugeotStyle } from "./peugeot/peugeot";
import { CitroenStyle } from "./citroen/citroen";
import { OpelStyle } from "./opel/opel";
import { JeepStyle } from "./jeep/jeep";
import { FiatStyle } from "./fiat/fiat";
import { FiatProfessionalStyle } from "./fiat-professional/fp";
import {
  PeugeotFontSizes,
  CitroenFontSizes,
  DSFontSizes,
  OpelFontSizes,
  FiatFontSizes,
  FPFontSizes,
  AlfaFontSizes,
  JeepFontSizes,
} from "./fontSizes";
import { device } from "./breakpoints";
import { DSStyle } from "./ds-automobiles/ds";
import { AlfaStyle } from "./alfa-romeo/alfa";

export const GlobalStyle = createGlobalStyle`
  // https://hankchizljaw.com/wrote/a-modern-css-reset/
  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default padding */
  ul[class],
  ol[class] {
    padding: 0;
  }

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd,
  hr {
    margin: 0;
  }

  /* Set core body defaults */
  body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    overflow-x: hidden;
  }

  /* Remove list styles on ul, ol elements with a class attribute */
  ul[class],
  ol[class] {
    list-style: none;
  }

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  img {
    max-width: 100%;
    display: block;
  }

  /* Natural flow and rhythm in articles by default */
  article > * + * {
    margin-top: 1em;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  /* Remove all animations and transitions for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  .title-h1 {
    margin-top: var(--sp80);
    margin-bottom: var(--sp64);
    word-break: break-word;
    @media ${device.mobileMax}{
      margin-left: var(--sp24);
      margin-bottom: var(--sp48);
    }
  }
  .title-h2 {
    margin-bottom: var(--sp48);
  }

  .App {
    display: grid;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;

    &.DS{
      background: #000000;
    }

    header {
      display: flex;
      align-items: center;
      .inner {
        width: 100%;
        max-width: 1312px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        padding: 0 var(--sp32);
        @media ${device.mobileMax}{
          padding: 0 var(--sp24);
        }
      }

      height: 100px;
      width: 100%;


      ul {
        display: flex;
        * {
          margin: 0 50px;
        }
      }
    }

    main {
      width: 100%;
      max-width: 1312px;
      margin: 0 auto;
      padding: 0 2rem;

      @media ${device.mobileMax}{
        padding: 0;
      }
    }

    
  }

  :root{
    --sp8: 8px;
    --sp16: 16px;
    --sp24: 24px;
    --sp32: 32px;
    --sp48: 48px;
    --sp64: 64px;
    --sp80: 80px;
  }

  /* Fonts */

  @font-face {
    font-family: "Peugeot";
    src: url(${PeugeotRegular}) format('truetype');
  }
  @font-face {
    font-family: "PeugeotBold";
    src: url(${PeugeotBold}) format('truetype');
  }
  
  @font-face {
    font-family: "Citroen";
    src: url(${CitroenRegular}) format('truetype');
  }

  @font-face {
    font-family: "CitroenBold";
    src: url(${CitroenBold}) format('truetype');
  }

  @font-face {
    font-family: "DS";
    src: url(${DSRegular}) format('truetype');
  }

  @font-face {
    font-family: "DSBold";
    src: url(${DSBold}) format('truetype');
  }

  @font-face {
    font-family: "DSTitleMediumBold";
    src: url(${DSTitleMediumBold}) format('truetype');
  }

  @font-face {
    font-family: "DSTitleMediumRegular";
    src: url(${DSTitleMediumRegular}) format('truetype');
  }

  @font-face {
    font-family: "Opel";
    src: url(${OpelRegular}) format('truetype');
  }
  @font-face {
    font-family: "OpelBold";
    src: url(${OpelBold}) format('truetype');
  }
  @font-face {
    font-family: "OpelLight";
    src: url(${OpelLight}) format('truetype');
  }
  @font-face {
    font-family: "RobotoRegular";
    src: url(${RobotoRegular}) format('truetype');
  }
  @font-face {
    font-family: "RobotoBold";
    src: url(${RobotoBold}) format('truetype');
  }
  @font-face {
    font-family: "MontserratRegular";
    src: url(${MontserratRegular}) format('truetype');
  }
  @font-face {
    font-family: "OpenSans";
    src: url(${OpenSans}) format('truetype');
  }
  @font-face {
    font-family: "OpenSansBold";
    src: url(${OpenSansBold}) format('truetype');
  }
  
  .PEU {
    font-family: "Peugeot";
    ${PeugeotStyle};
    ${PeugeotFontSizes};
    background: #F0F0F0;
  }
  .CIT {
    font-family: "Citroen";
    ${CitroenStyle};
    ${CitroenFontSizes};
  }

  .DS {
    font-family: "DS";
    ${DSStyle};
    ${DSFontSizes};
  }
  .OPE {
    font-family: "Opel";
    ${OpelStyle};
    ${OpelFontSizes};
  }
  .JEE {
    font-family: "RobotoRegular";
    ${JeepStyle};
    ${JeepFontSizes};
  }
  .FIA {
    font-family: "OpenSans";
    ${FiatStyle};
    ${FiatFontSizes};
  }
  .FIP {
    font-family: "OpenSans";
    ${FiatProfessionalStyle};
    ${FPFontSizes};
  }
  .ALF {
    font-family: "ApexNew-Book", "Helvetica,Arial", "sans-serif";
    ${AlfaStyle};
    ${AlfaFontSizes};
  }
  }
`;
