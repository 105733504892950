import styled from "styled-components";

export const CarCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--sp24);
`;

export const CarCategoryContainer = styled.div`
  h2 {
    margin-bottom: var(--sp8);
  }
  hr {
    margin-bottom: var(--sp24);
  }
  margin-bottom: var(--sp80);
`;

export const VerticalDivider = styled.hr``;

export const CategoryHeader = styled.h4``;
