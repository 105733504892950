import { useContext } from "react";
import { AllDataContext } from "../../lib/dataContext";
import { HeaderContainer } from "../../styles/containers";
import CitroenHeader from "./citroen";
import DSHeader from "./ds";
import PeugeotHeader from "./peugeot";
import OpelHeader from "./opel";
import JeepHeader from "./jeep";
import FiatHeader from "./fiat";
import FPHeader from "./fp";
import AlfaHeader from "./alfa";

const Header = () => {
  const data = useContext(AllDataContext);
  return (
    <>
      {data.brandCode !== "ALF" &&
        <HeaderContainer>
          <div className="inner">
            {data.brandCode === "PEU" && <PeugeotHeader />}
            {data.brandCode === "CIT" && <CitroenHeader />}
            {data.brandCode === "DS" && <DSHeader />}
            {data.brandCode === "OPE" && <OpelHeader />}
            {data.brandCode === "JEE" && <JeepHeader />}
            {data.brandCode === "FIA" && <FiatHeader />}
            {data.brandCode === "FIP" && <FPHeader />}
            {data.brandCode === "ALF" && <AlfaHeader />}
          </div>
        </HeaderContainer>
      }
    </>
  );
};

export default Header;
