import CarCategory from "../../../components/car-category";
import _ from "lodash";
import { Car } from "../../../../../common/typedefs";
import { CarSelectionContainer } from "../../../styles/containers";
import { Fragment, useContext, useEffect } from "react";
import { getBrand } from "../../../lib/getBrandFromCode";
import { sendToGTM } from "../../../lib/sendToGTM";
import { AllDataContext } from "../../../lib/dataContext";
import CarCard from "../../../components/car-card";
import { CarCardContainer } from "../../../components/car-category/styles";
interface CarsWithModelseries {
  modelseries: number;
  cars: Car[];
}

interface Props {
  pageType: string;
}

const CarSelection = (props: Props) => {
  const { pageType } = props;
  const { cars, groupCars, brandCode } = useContext(AllDataContext);

  const filteredCars = cars.filter((car) => {
    if (pageType === "offer") {
      return car.IsAvailableForOffer === true;
    } else {
      return car.IsAvailableForTestDrive === true;
    }
  });

  const checkVPVtitle = () => {
    return pageType === "offer"
      ? `Få tilbud på en ${getBrand(brandCode)}`
      : `Prøvekjør en ${getBrand(brandCode)}`;
  };

  const checkVPVpath = () => {
    return pageType === "offer"
      ? "/tilbud/velg-bil"
      : "/book-provekjoring/velg-bil";
  };

  useEffect(() => {
    sendToGTM({
      event: "vpv",
      vpv_title: checkVPVtitle(),
      vpv_path: checkVPVpath(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const res: CarsWithModelseries[] = _(filteredCars)
    .groupBy("modelseries")
    .sortBy((x) => filteredCars.indexOf(x[0]))
    .map((value, key) => ({ modelseries: key, cars: value }))
    .value();

  let outerInd = 0;
  for (const series of res) {
    // eslint-disable-next-line
    series.cars.forEach((el) => {
      outerInd++;
      el["tabId"] = outerInd;
    });
  }
  return (
    <CarSelectionContainer role="group">
      <h2 className={brandCode === "OPE" ? "title-h2-light" : "title-h2"}>
        Velg modell
      </h2>
      {groupCars ? (
        res.map((x: CarsWithModelseries) => (
          <CarCategory cars={x.cars} key={x.modelseries} pageType={pageType} />
        ))
      ) : (
        <CarCardContainer>
          {res.map((x: CarsWithModelseries, modelSeriesIndex: number) =>
            x.cars?.map((car: Car, carIndex: number) => (
              <Fragment key={carIndex * modelSeriesIndex}>
                <CarCard data={car} pageType={pageType} />
              </Fragment>
            ))
          )}
        </CarCardContainer>
      )}
    </CarSelectionContainer>
  );
};

export default CarSelection;
