import { useContext } from "react";
import { AllDataContext } from "../../lib/dataContext";
import DSFooter from "./ds";
import PeugeotFooter from "./peugeot";
import CitroenFooter from "./citroen";
import OpelFooter from "./opel";
import JeepFooter from "./jeep";
import FiatFooter from "./fiat";
import FPFooter from "./fiat";
import AlfaFooter from "./alfa";

const Footer = () => {
  const data = useContext(AllDataContext);

  return (
    <>
      {data.brandCode === "PEU" && <PeugeotFooter />}
      {data.brandCode === "CIT" && <CitroenFooter />}
      {data.brandCode === "DS" && <DSFooter />}
      {data.brandCode === "OPE" && <OpelFooter />}
      {data.brandCode === "JEE" && <JeepFooter />}
      {data.brandCode === "FIA" && <FiatFooter />}
      {data.brandCode === "FIP" && <FPFooter />}
      {/* {data.brandCode === "ALF" && <AlfaFooter />} */}
    </>
  );
};

export default Footer;
