import { Fragment } from "react";
import { Car } from "../../../../common/typedefs";
import CarCard from "../car-card";
import {
  CarCardContainer,
  CarCategoryContainer,
  VerticalDivider,
  CategoryHeader,
} from "./styles";

interface Props {
  cars: Car[];
  pageType: string;
}

const CarCategory = (props: Props) => {
  const { cars, pageType } = props;
  const searchRegExp = /\s/g;
  const replaceWith = '_';
  const sectionID = cars[0].modelseries.toLowerCase().replace(searchRegExp, replaceWith);

  return (
    <CarCategoryContainer>
      <CategoryHeader id={sectionID}>
        {cars[0].modelseries}
      </CategoryHeader>
      <VerticalDivider />
      <CarCardContainer>
        {cars?.map((car: Car, index: number) => (
          <Fragment key={index}>
            <CarCard data={car} pageType={pageType} />
          </Fragment>
        ))}
      </CarCardContainer>
    </CarCategoryContainer>
  );
};

export default CarCategory;
