import DSLogo from "../../logos/ds";

const DSHeader = () => {
  return (
    <>
      <a href="https://www.dsautomobiles.no/">
        <DSLogo />
      </a>
      <a
        href="https://www.dsautomobiles.no/"
        rel="noopener noreferrer"
        target="_blank"
      >
        Til DSAutomobiles.no
      </a>
    </>
  );
};

export default DSHeader;
