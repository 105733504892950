import styled from "styled-components";
import JeepLogo from "../../logos/jeep";

const HeaderLink = styled.a`
  font-size: 12px;
`;

const JeepHeader = () => {
  return (
    <>
      <a href="https://www.jeep.no/">
        <JeepLogo />
      </a>
      <HeaderLink
        href="https://www.jeep.no/"
        rel="noopener noreferrer"
        target="_blank"
      >
        TIL JEEP.NO
      </HeaderLink>
    </>
  );
};

export default JeepHeader;
