import { device } from "./breakpoints";
import { css } from "styled-components";

export const PeugeotFontSizes = css`
  h1,
  .h1 {
    font-size: 2.5rem;
    line-height: 3.125rem;
  }
  h2,
  .h2 {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
  h3,
  .h3 {
    font-size: 1.5625rem;
    line-height: 2.1875rem;
  }
  h4,
  .h4 {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
  h5,
  .h5 {
    font-size: 1rem;
    line-height: 1.5625rem;
  }
  h6,
  .h6 {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  p,
  .p  {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  .p2 {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  @media ${device.mobileMax} {
    h1,
    .h1 {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    h2,
    .h2 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    h3,
    .h3 {
      font-size: 1.25rem;
      line-height: 1.6785rem;
    }
    h4,
    .h4 {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    h5,
    .h5 {
      font-size: 1rem;
      line-height: 1.5625rem;
    }
    h6,
    .h6 {
      font-size: 0.75rem;
      line-height: 1.25rem;
    }
  }
`;

export const CitroenFontSizes = css`
  h1,
  .h1 {
    font-size: 2.5rem;
    line-height: 3.125rem;
  }
  h2,
  .h2 {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
  h3,
  .h3 {
    font-size: 1.5625rem;
    line-height: 2.1875rem;
  }
  h4,
  .h4 {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
  h5,
  .h5 {
    font-size: 1rem;
    line-height: 1.5625rem;
  }
  h6,
  .h6 {
    font-size: 1rem;
    line-height: 1.5625rem;
  }

  p,
  .p  {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .p2 {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  @media ${device.mobileMax} {
    h1,
    .h1 {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    h2,
    .h2 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    h3,
    .h3 {
      font-size: 1.25rem;
      line-height: 1.6785rem;
    }
    h4,
    .h4 {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    h5,
    .h5 {
      font-size: 1rem;
      line-height: 1.5625rem;
    }
    h6,
    .h6 {
      font-size: 1rem;
      line-height: 1.5625rem;
    }
  }
`;

export const DSFontSizes = css`
  h1,
  .h1 {
    font-size: 2.5rem;
    line-height: 3.125rem;
  }
  h2,
  .h2 {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
  h3,
  .h3 {
    font-size: 1.5625rem;
    line-height: 2.1875rem;
  }
  h4,
  .h4 {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
  h5,
  .h5 {
    font-size: 1rem;
    line-height: 1.5625rem;
  }
  h6,
  .h6 {
    font-size: 1rem;
    line-height: 1.5625rem;
  }

  p,
  .p  {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .p2 {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  @media ${device.mobileMax} {
    h1,
    .h1 {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    h2,
    .h2 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    h3,
    .h3 {
      font-size: 1.25rem;
      line-height: 1.6785rem;
    }
    h4,
    .h4 {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    h5,
    .h5 {
      font-size: 1rem;
      line-height: 1.5625rem;
    }
    h6,
    .h6 {
      font-size: 1rem;
      line-height: 1.5625rem;
    }
  }
`;

export const OpelFontSizes = css`
  h1,
  .h1 {
    font-size: 2.5rem;
    line-height: 3.125rem;
  }
  h2,
  .h2 {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
  h3,
  .h3 {
    font-size: 1.5625rem;
    line-height: 2.1875rem;
  }
  h4,
  .h4 {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
  h5,
  .h5 {
    font-size: 1rem;
    line-height: 1.5625rem;
  }
  h6,
  .h6 {
    font-size: 1rem;
    line-height: 1.5625rem;
  }

  p,
  .p  {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .p2 {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  @media ${device.mobileMax} {
    h1,
    .h1 {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    h2,
    .h2 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    h3,
    .h3 {
      font-size: 1.25rem;
      line-height: 1.6785rem;
    }
    h4,
    .h4 {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    h5,
    .h5 {
      font-size: 1rem;
      line-height: 1.5625rem;
    }
    h6,
    .h6 {
      font-size: 1rem;
      line-height: 1.5625rem;
    }
  }
`;
export const AlfaFontSizes = css`
  h1,
  .h1 {
    font-size: 2.5rem;
    line-height: 3.125rem;
  }
  h2,
  .h2 {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
  h3,
  .h3 {
    font-size: 1.5625rem;
    line-height: 2.1875rem;
  }
  h4,
  .h4 {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
  h5,
  .h5 {
    font-size: 1rem;
    line-height: 1.5625rem;
  }
  h6,
  .h6 {
    font-size: 1rem;
    line-height: 1.5625rem;
  }

  p,
  .p  {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .p2 {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  @media ${device.mobileMax} {
    h1,
    .h1 {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    h2,
    .h2 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    h3,
    .h3 {
      font-size: 1.25rem;
      line-height: 1.6785rem;
    }
    h4,
    .h4 {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    h5,
    .h5 {
      font-size: 1rem;
      line-height: 1.5625rem;
    }
    h6,
    .h6 {
      font-size: 1rem;
      line-height: 1.5625rem;
    }
  }
`;
export const JeepFontSizes = css`
  h1,
  .h1 {
    font-size: 2.5rem;
    line-height: 3.125rem;
  }
  h2,
  .h2 {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
  h3,
  .h3 {
    font-size: 1.5625rem;
    line-height: 2.1875rem;
  }
  h4,
  .h4 {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
  h5,
  .h5 {
    font-size: 1rem;
    line-height: 1.5625rem;
  }
  h6,
  .h6 {
    font-size: 1rem;
    line-height: 1.5625rem;
  }

  p,
  .p  {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .p2 {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  @media ${device.mobileMax} {
    h1,
    .h1 {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    h2,
    .h2 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    h3,
    .h3 {
      font-size: 1.25rem;
      line-height: 1.6785rem;
    }
    h4,
    .h4 {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    h5,
    .h5 {
      font-size: 1rem;
      line-height: 1.5625rem;
    }
    h6,
    .h6 {
      font-size: 1rem;
      line-height: 1.5625rem;
    }
  }
`;

export const FiatFontSizes = css`
  h1,
  .h1 {
    font-size: 2.5rem;
    line-height: 3.125rem;
  }
  h2,
  .h2 {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
  h3,
  .h3 {
    font-size: 1.5625rem;
    line-height: 2.1875rem;
  }
  h4,
  .h4 {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
  h5,
  .h5 {
    font-size: 1rem;
    line-height: 1.5625rem;
  }
  h6,
  .h6 {
    font-size: 1rem;
    line-height: 1.5625rem;
  }

  p,
  .p  {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .p2 {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  @media ${device.mobileMax} {
    h1,
    .h1 {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    h2,
    .h2 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    h3,
    .h3 {
      font-size: 1.25rem;
      line-height: 1.6785rem;
    }
    h4,
    .h4 {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    h5,
    .h5 {
      font-size: 1rem;
      line-height: 1.5625rem;
    }
    h6,
    .h6 {
      font-size: 1rem;
      line-height: 1.5625rem;
    }
  }
`;
export const FPFontSizes = css`
  h1,
  .h1 {
    font-size: 2.5rem;
    line-height: 3.125rem;
  }
  h2,
  .h2 {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
  h3,
  .h3 {
    font-size: 1.5625rem;
    line-height: 2.1875rem;
  }
  h4,
  .h4 {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
  h5,
  .h5 {
    font-size: 1rem;
    line-height: 1.5625rem;
  }
  h6,
  .h6 {
    font-size: 1rem;
    line-height: 1.5625rem;
  }

  p,
  .p  {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .p2 {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  @media ${device.mobileMax} {
    h1,
    .h1 {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    h2,
    .h2 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    h3,
    .h3 {
      font-size: 1.25rem;
      line-height: 1.6785rem;
    }
    h4,
    .h4 {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    h5,
    .h5 {
      font-size: 1rem;
      line-height: 1.5625rem;
    }
    h6,
    .h6 {
      font-size: 1rem;
      line-height: 1.5625rem;
    }
  }
`;
